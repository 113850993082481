import React, { useState } from "react";
import DialogCentroCusto from "../../pages/cadastro/centrocusto/DialogCentroCusto";
import CentroCustoService from "../../services/CentroCustoService";
import AutoCompleteAsyncPersist from "./autocomplete/AutoCompleteAsyncPersist";

const CentroCustoAutoComplete = (props) => {
  const [show, setShow] = useState(false);

  const handleFetchAPI = async (valueSearch) => {
    const response = await CentroCustoService.buscarCentroCusto(
      valueSearch,
      null,
      0,
      props.somenteVinculadosUsuario ? true : false
    );
    return response.content;
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <AutoCompleteAsyncPersist
      name="autoCentroCusto"
      label="Centro de Custo"
      permission={["manter_centro_custo"]}
      itemLabel={"descricao"}
      fullWidth
      showCadastro={show}
      setShowCadastro={setShow}
      onFetchAPI={handleFetchAPI}
      {...props}
      renderPersist={(value) => {
        return (
          <DialogCentroCusto
            show={show}
            onRequestClose={() => handleClose()}
            initValue={value}
          />
        );
      }}
    />
  );
};

export default CentroCustoAutoComplete;
