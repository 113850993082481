import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import React from "react";
import { Edit } from "react-feather";

const AlterarButton = ({ onClick = () => {}, title = "Alterar", carregando=false }) => {
  return (
    <>
      <LoadingButton
        type="submit"
        variant="contained"
        color="primary"
        startIcon={<Edit />}
        onClick={() => onClick()}
        loading={carregando}
      >
        {title}
      </LoadingButton>
    </>
  );
};

export default AlterarButton;
