//import axios from "../utils/axios";
import Axios from "axios";

function pesquisarMutuario(
  nome,
  cpf,
  matricula,
  identificadorExterno,
  centroCusto = null,
  status,
  page = 0
) {
  return new Promise((resolve, reject) => {
    const vo = {
      matricula: matricula,
      pessoa: { nome: nome, documento: cpf },
      centroCusto: centroCusto,
      identificadorExterno,
      status,
    };
    Axios.post(`/mutuario/pesquisa/${page}`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function buscarMutuario(idMutuario) {
  return new Promise((resolve, reject) => {
    Axios.get(`/mutuario/${idMutuario}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function buscaDadosCardColaboradores(centroCusto) {
  return new Promise((resolve, reject) => {
    let vo = undefined;

    if (centroCusto) {
      vo = centroCusto;
    }

    Axios.post(`/mutuario/buscaDadosCardColaboradores/`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function headCountDashboard(centroCusto) {
  return new Promise((resolve, reject) => {
    let vo = undefined;

    if (centroCusto) {
      vo = centroCusto;
    }

    Axios.post(`/mutuario/headCount/`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function turnover(centroCusto) {
  return new Promise((resolve, reject) => {
    let vo = undefined;

    if (centroCusto) {
      vo = centroCusto;
    }

    Axios.post(`/mutuario/turnover/`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function buscaDadosCardFerias(centroCusto) {
  return new Promise((resolve, reject) => {
    let vo = undefined;

    if (centroCusto) {
      vo = centroCusto;
    }

    Axios.post(`/mutuario/buscaDadosCardFerias/`, vo)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function save({
  cidadeNatal,
  identificadorExterno,
  ufNatal,
  nacionalidade,
  raca,
  estadoCivil,
  idMutuario,
  idPessoa,
  cpf,
  nome,
  dataNascimento,
  nomePai,
  nomeMae,
  sexo,
  email,
  telefone,
  matricula,
  pis,
  centroCustoVO,
  lotacaoVO,
  regimeContratacaoVO,
  cargoVO,
  salario,
  dataAdmissao,
  tipoPeriodoExperiencia,
  dataTerminoPrimeiroPeriodoExperiencia,
  dataTerminoSegundoPeriodoExperiencia,
  registraPonto = "SIM",
  registraPontoOffline = "SIM",
  registraPontoPeloAplicativo = "SIM",
  registraPontoPeloNavegador = "SIM",
  registraPontoPeloNavegadorMobile = "SIM",
  registraPontoPeloToten = "SIM",
  validaReconhecimentoFacial = "SIM",
  ehPlantonista = "NAO",
  validarSpoofing = "NAO",
  rg,
  numeroCtps
}) {
  return new Promise((resolve, reject) => {
    const pessoa = {
      cidadeNatal,
      ufNatal,
      idPessoa,
      documento: cpf,
      validarSpoofing,
      nome,
      dataNascimento,
      email,
      telefone,
      sexo,
      nomePai,
      nomeMae,
      nacionalidade,
      raca,
      estadoCivil,
      rg,
      numeroCtps
    };

    const centroCusto = {
      idCentroCusto: centroCustoVO.idCentroCusto,
    };

    const lotacao = {
      idLotacao: lotacaoVO.idLotacao,
    };

    const regimeContratacao = {
      idRegimeContratacao: regimeContratacaoVO.idRegimeContratacao,
    };

    const cargo = {
      idCargo: cargoVO.idCargo,
    };

    const mutuarioVO = {
      idMutuario,
      matricula,
      numeroIdentificacaoSocial: pis,
      dataAdmissao,
      pessoa,
      identificadorExterno,
      centroCusto,
      lotacao,
      regimeContratacao,
      cargo,
      salario,
      tipoPeriodoExperiencia,
      dataTerminoPrimeiroPeriodoExperiencia,
      dataTerminoSegundoPeriodoExperiencia,
      registraPonto,
      registraPontoOffline,
      registraPontoPeloAplicativo,
      registraPontoPeloNavegador,
      registraPontoPeloNavegadorMobile,
      registraPontoPeloToten,
      validaReconhecimentoFacial,
      validarSpoofing,
      ehPlantonista,
    };

    Axios.post(`/mutuario`, JSON.stringify(mutuarioVO))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function relatorio(
  mutuarios,
  centrosCusto,
  lotacoes,
  regimesContratacao,
  cargos,
  status,
  page = 0
) {
  return new Promise((resolve, reject) => {
    const vo = {
      mutuarios: mutuarios,
      centrosCusto: centrosCusto,
      lotacoes: lotacoes,
      regimesContratacao: regimesContratacao,
      cargos: cargos,
      status: status,
    };

    Axios.post(`/mutuario/relatorio/${page}`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}



function relatorioPeriodoExperiencia(
  mutuarios,
  centrosCusto,
  lotacoes,
  regimesContratacao,
  cargos,      
    dataInicio,
    dataFim,
  page = 0
) {
  return new Promise((resolve, reject) => {
    const vo = {
      mutuarios: mutuarios,
      centrosCusto: centrosCusto,
      lotacoes: lotacoes,
      regimesContratacao: regimesContratacao,
      cargos: cargos,      
      dataInicio,
      dataFim
    };

    Axios.post(`/mutuario/relatorioPeriodoExperiencia/${page}`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


function pesquisaColaboradoresAlteracaoLote(
  mutuarios,
  centrosCusto,
  lotacoes,
  regimesContratacao,
  cargos
) {
  return new Promise((resolve, reject) => {
    const vo = {
      mutuarios: mutuarios,
      centrosCusto: centrosCusto,
      lotacoes: lotacoes,
      regimesContratacao: regimesContratacao,
      cargos: cargos,
    };

    Axios.post(
      `/mutuario/pesquisaColaboradoresAlteracaoLote`,
      JSON.stringify(vo)
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function listarTodos() {
  return new Promise((resolve, reject) => {
    Axios.get(`/mutuario/listarTodos/`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function demitir(
  mutuarioSelecionado,
  dataDesligamento,
  motivoDesligamento,
  suspenderAcessos,
  tipoTurnover
) {
  return new Promise((resolve, reject) => {
    mutuarioSelecionado.dtDesligamento = dataDesligamento;
    mutuarioSelecionado.motivoDesligamento = motivoDesligamento;
    mutuarioSelecionado.suspenderAcessos = suspenderAcessos;
    mutuarioSelecionado.tipoTurnover = tipoTurnover;

    Axios.post(`/mutuario/demitir/`, mutuarioSelecionado)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function reademitir(idMutuario) {
  return new Promise((resolve, reject) => {
    Axios.get(`/mutuario/reademitir/${idMutuario}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function excluir(idMutuario) {
  return new Promise((resolve, reject) => {
    Axios.delete(`/mutuario/${idMutuario}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function anterior(idMutuario) {
  return new Promise((resolve, reject) => {
    Axios.get(`/mutuario/anterior/${idMutuario}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function proximo(idMutuario) {
  return new Promise((resolve, reject) => {
    Axios.get(`/mutuario/proximo/${idMutuario}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function importarViaArquivo(file) {
  const formData = new FormData();
  formData.append("file", file);
  return Axios.post("/mutuario/importarViaArquivo", formData, {
    headers: { "content-type": "multipart/form-data;" },
  });
}

function relatorioAbsenteismo(
  tipoAjuste,
  tipoPeriodoAbono,
  tipoFalta,
  dataInicio,
  dataFim,
  tipo,
  mutuarios,
  centrosCusto,
  lotacoes,
  regimesContratacao,
  cargos,
  page
) {
  return new Promise((resolve, reject) => {
    const vo = {
      motivoAbonos: tipoAjuste,
      tipoPeriodoAbono,
      tipoFalta,
      dataInicio,
      dataFim,
      tipo,
      mutuarios,
      centrosCusto,
      lotacoes,
      regimesContratacao,
      cargos,
    };

    Axios.post(`/mutuario/relatorioAbsenteismo/${page}`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function atualizarEmLote({
  mutuarios,
  salario,
  cargo,
  regimeContratacao,
  lotacao,
  centroCusto,
  registraPonto,
  registraPontoOffline,
  registraPontoPeloAplicativo,
  registraPontoPeloNavegador,
  registraPontoPeloNavegadorMobile,
  registraPontoPeloToten,
  validaReconhecimentoFacial,
  ehPlantonista,
  faixaIPInicial,
  faixaIPFinal,
  tipoRestricao,
  validarSpoofing,
}) {
  return new Promise((resolve, reject) => {
    const vo = {
      cargo,
      regimeContratacao,
      lotacao,
      centroCusto,
      registraPonto,
      salario,
      registraPontoOffline,
      registraPontoPeloAplicativo,
      registraPontoPeloNavegador,
      registraPontoPeloNavegadorMobile,
      registraPontoPeloToten,
      validaReconhecimentoFacial,
      ehPlantonista,
      mutuarios,
      enderecoIpInicial: faixaIPInicial,
      enderecoIpFinal: faixaIPFinal,
      tipoRestricao,
      validarSpoofing,
    };

    Axios.post(`/mutuario/alterarEmLote`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function relatorioTurnover(
  dataInicio,
  centrosCusto,
  lotacoes,
  regimesContratacao,
  cargos
) {
  return new Promise((resolve, reject) => {
    const vo = {
      dataInicio,
      centrosCusto,
      lotacoes,
      regimesContratacao,
      cargos,
    };

    Axios.post(`/mutuario/relatorioTurnover`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const MutuarioService = {
  pesquisarMutuario,
  buscarMutuario,
  anterior,
  proximo,
  excluir,
  relatorioAbsenteismo,
  listarTodos,
  headCountDashboard,
  save,
  relatorioTurnover,
  demitir,
  turnover,
  relatorio,
  atualizarEmLote,
  reademitir,
  relatorioPeriodoExperiencia,
  importarViaArquivo,
  pesquisaColaboradoresAlteracaoLote,
  buscaDadosCardColaboradores,
  buscaDadosCardFerias,
};

export default MutuarioService;
