//import axios from "../utils/axios";
import Axios from "axios";

function buscarPessoaUsuarioLogado() {
  return new Promise((resolve, reject) => {
    Axios.get(`/pessoa/usuarioLogado`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function updatePessoaUsuarioLogado({
  email,
  telefone,
  nome,
  dataNascimento,
  bibliografia,
}) {
  return new Promise((resolve, reject) => {
    const vo = {
      email,
      telefone,
      nome,
      dataNascimento,
      bibliografia,
    };
    Axios.put(`/pessoa/usuarioLogado`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function buscarPorDocumento(cpf) {
  return new Promise((resolve, reject) => {
    Axios.get(`/pessoa/buscarPorDocumento/${cpf}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function pesquisar(documento, email, nome, razaoSocial, page = 0) {
  return new Promise((resolve, reject) => {
    var vo = {
      documento: documento,
      email: email,
      nome: nome,
      razaoSocial: razaoSocial,
    };

    Axios.post(`/pessoa/pesquisa/${page}`, vo)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const PessoaService = {
  buscarPessoaUsuarioLogado,
  updatePessoaUsuarioLogado,
  buscarPorDocumento,
  pesquisar,
};

export default PessoaService;
