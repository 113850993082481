import React, { useState } from "react";
import DialogLotacao from "../../pages/cadastro/lotacao/DialogLotacao";
import LotacaoService from "../../services/LotacaoService";
import AutoCompleteAsyncPersist from "./autocomplete/AutoCompleteAsyncPersist";

const LotacaoAutoComplete = (props) => {
  const [show, setShow] = useState(false);

  const handleFetchAPI = async (valueSearch) => {
    const response = await LotacaoService.buscarLotacao(null, valueSearch, 0);
    return response.content;
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <AutoCompleteAsyncPersist
      name="autoLotacao"
      label="Lotação"
      permission={["manter_lotacao"]}
      itemLabel={"descricao"}
      fullWidth
      showCadastro={show}
      setShowCadastro={setShow}
      onFetchAPI={handleFetchAPI}
      {...props}
      renderPersist={(value) => {
        return (
          <DialogLotacao
            show={show}
            onRequestClose={() => handleClose()}
            initValue={value}
          />
        );
      }}
    />
  );
};

export default LotacaoAutoComplete;
