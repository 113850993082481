//import axios from "../utils/axios";
import Axios from "axios";

function buscarLotacao(codigo, descricao, page = 0) {
  return new Promise((resolve, reject) => {
    const vo = {
      codigo: codigo,
      descricao: descricao,
    };
    Axios.post(`/lotacao/pesquisa/${page}`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function createLotacao({
  codigo,
  descricao,
  sigla,
  endereco,
  cnpj,
  email,
  telefone,
}) {
  return new Promise((resolve, reject) => {
    const vo = {
      codigo,
      descricao,
      sigla,
      endereco,
      cnpj,
      email,
      telefone,
    };
    Axios.post(`/lotacao`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function updateLotacao({
  idLotacao,
  codigo,
  descricao,
  sigla,
  endereco,
  cnpj,
  email,
  telefone,
}) {
  return new Promise((resolve, reject) => {
    const vo = {
      idLotacao,
      codigo,
      descricao,
      sigla,
      endereco,
      cnpj,
      email,
      telefone,
    };
    Axios.put(`/lotacao`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function deleteLotacao(idLotacao) {
  return new Promise((resolve, reject) => {
    Axios.delete(`/lotacao/${idLotacao}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const LotacaoService = {
  buscarLotacao,
  createLotacao,
  updateLotacao,
  deleteLotacao,
};

export default LotacaoService;
