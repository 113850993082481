import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CircularProgress,
  IconButton as MuiIconButton,
  Tooltip,
} from "@mui/material";
import { withTheme } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";
import PontoService from "../services/PontoService";
import Alert from "./alert/Alert";
import ConfirmDialog from "./ConfirmDialog";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function RegistraPontoDropdown(props) {
  const [confirmDialogBaterPonto, setConfirmDialogBaterPonto] = useState(false);
  const [carregando, setCarregando] = useState(false);
  const [localizacao, setLocalizacao] = useState();
  const [mutuario, setMutuario] = useState();

  const { user } = useSelector((state) => state.authReducer);

  useEffect(() => {
    getLocationConstant();

    if (user.employee && user.employee.length === 1) {
      if (user.employee[0].registraPonto === 'SIM') {
        setMutuario(user.employee[0]);
      }
    }
  }, []);

  const preparaBaterPonto = () => {
    setConfirmDialogBaterPonto(true);
  };

  const getLocationConstant = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (event) {
          setLocalizacao(event);
        },
        function (error) {
          console.log("Error code " + error.code + ". " + error.message);
        },
        { timeout: 30000, enableHighAccuracy: true, maximumAge: 75000 }
      );
    } else {
      alert(
        "Seu navegador ou dispositivo não possui suporte para geolocalização"
      );
    }
  };

  const handleConfirmBaterPonto = () => {
    setCarregando(true);

    PontoService.bater(
      mutuario.idMutuario,
      localizacao && localizacao.coords ? localizacao.coords.latitude : null,
      localizacao && localizacao.coords ? localizacao.coords.longitude : null,
      localizacao && localizacao.coords ? localizacao.coords.accuracy : null
    )
      .then(() => {
        Alert.success("Sucesso", "Ponto registrado com sucesso!");
      })
      .finally(() => {
        setCarregando(false);
      });
  };

  return (
    <React.Fragment>
      {confirmDialogBaterPonto && (
        <ConfirmDialog
          title="Confirmação"
          open={confirmDialogBaterPonto}
          onRequestClose={() => setConfirmDialogBaterPonto(false)}
          onConfirm={handleConfirmBaterPonto}
        >
          Deseja realmente efetuar a batida de ponto?
        </ConfirmDialog>
      )}

      {mutuario && (
        <>
          {carregando ? (
            <CircularProgress
              size={25}
              style={{ color: props.theme.palette.primary.main }}
            />
          ) : (
            <Tooltip title="Registrar Ponto">
              <IconButton
                id="buttonRegistroPontoAtalho"
                aria-owns={Boolean(null) ? "menu-appbar" : undefined}
                aria-haspopup="true"
                onClick={preparaBaterPonto}
                color="inherit"
                size="large">
                <FontAwesomeIcon
                  icon={faBullseye}
                  spin={false}
                  pulse={false}
                  style={{ color: props.theme.palette.primary.main }}
                />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
    </React.Fragment>
  );
}

export default withTheme(RegistraPontoDropdown);
