import React from "react";

import TextField from "./TextField";
import MaskUtil from "../../../utils/MaskUtil";

const TextFieldTelefone = (props) => {
  return (
    <TextField
      {...props}
      type="tel"
      onChange={(e) => {
        var val = MaskUtil.undoMask(e.target.value);
        props.onChange(val);
      }}
      value={MaskUtil.cellPhone(props.value)}
    ></TextField>
  );
};

export default TextFieldTelefone;
