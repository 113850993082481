//import axios from "../utils/axios";
import Axios from "axios";

function buscarCentroCusto(
  descricao,
  sigla,
  page = 0,
  somenteVinculadosUsuario = false
) {
  return new Promise((resolve, reject) => {
    const vo = {
      descricao: descricao,
      sigla: sigla,
      somenteVinculadosUsuario,
    };
    Axios.post(`/centroCusto/pesquisa/${page}`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function createCentroCusto({
  cno,
  caepf,
  codigo,
  descricao,
  sigla,
  endereco,
  cnpj,
  email,
  telefone,
  cei,
  numeroRep,
}) {
  return new Promise((resolve, reject) => {
    const vo = {
      codigo,
      cno,
      caepf,
      descricao,
      sigla,
      endereco,
      cnpj,
      email,
      telefone,
      cei,
      numeroRep,
    };
    Axios.post(`/centroCusto`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function updateCentroCusto({
  cno,
  caepf,
  idCentroCusto,
  codigo,
  descricao,
  sigla,
  endereco,
  cnpj,
  email,
  telefone,
  cei,
  numeroRep,
}) {
  return new Promise((resolve, reject) => {
    const vo = {
      idCentroCusto,
      cno,
      caepf,
      codigo,
      descricao,
      sigla,
      endereco,
      cnpj,
      email,
      telefone,
      cei,
      numeroRep,
    };
    Axios.put(`/centroCusto`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function deleteCentroCusto(idCentroCusto) {
  return new Promise((resolve, reject) => {
    Axios.delete(`/centroCusto/${idCentroCusto}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function alterarLogomarca(file, idCentroCusto) {
  const formData = new FormData();
  formData.append("file", file);
  return Axios.put(`/centroCusto/logomarca/${idCentroCusto}`, formData, {
    headers: { "content-type": "multipart/form-data;" },
  });
}

function deletarLogomarca(idCentroCusto) {
  return new Promise((resolve, reject) => {
    Axios.delete(`/centroCusto/logomarca/${idCentroCusto}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const CentroCustoService = {
  buscarCentroCusto,
  createCentroCusto,
  updateCentroCusto,
  deleteCentroCusto,
  alterarLogomarca,
  deletarLogomarca,
};

export default CentroCustoService;
