import React from "react";
import {
  Calendar as CalendarIcon,
  Clock,
  FileText,
  PlusSquare,
  ShoppingCart,
  Users,
} from "react-feather";
import { AiOutlineBank } from "react-icons/ai";
import { BiMessageAltCheck } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import { FaFileInvoiceDollar, FaTasks } from "react-icons/fa";
import { GiPayMoney } from "react-icons/gi";

import { BsGraphUpArrow } from "react-icons/bs";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { MdBorderColor } from "react-icons/md";
import {
  RiDashboardLine,
  RiLockPasswordLine,
  RiSurveyLine,
} from "react-icons/ri";
import { TiMessages } from "react-icons/ti";
import async from "../components/Async";
import { Search } from "@mui/icons-material";

const TokenMFA = async(() =>
  import("../pages/auth/TokenMFA")
);

const RelatorioInterIntraJornada = async(() =>
  import("../pages/relatorio/intraInterJornada/RelatorioInterIntraJornada")
);

const RelatorioInsights = async(() =>
  import("../pages/relatorio/insights/RelatorioInsights")
);

const RelatorioAuditoriaFoto = async(() =>
  import("../pages/relatorio/auditoriafoto/RelatorioAuditoriaFoto")
);

const RelatorioFaltas = async(() =>
  import("../pages/relatorio/falta/RelatorioFaltas")
);

const RelatorioSobreaviso = async(() =>
  import("../pages/relatorio/sobreaviso/RelatorioSobreaviso")
);
const RelatorioAdicionalNoturno = async(() =>
  import("../pages/relatorio/adicionalnoturna/RelatorioAdicionalNoturno")
);
const RelatorioDadosCadastrais = async(() =>
  import("../pages/relatorio/colaboradores/RelatorioDadosCadastrais")
);

const RelatorioPeriodoExperiencia = async(() =>
  import("../pages/relatorio/periodoexperiencia/RelatorioPeriodoExperiencia")
);

const RelatorioEscalaFolga = async(() =>
  import("../pages/relatorio/escalafolga/RelatorioEscalaFolga")
);
const RelatorioAbsenteismo = async(() =>
  import("../pages/relatorio/absenteismo/RelatorioAbsenteismo")
);
const ProgramarEscala = async(() =>
  import("../pages/cadastro/escalafolga/ProgramarEscala")
);
const EscalaFolga = async(() =>
  import("../pages/cadastro/escalafolga/EscalaFolga")
);
const Pricing = async(() => import("../pages/price/Pricing"));
const RelatorioFaturas = async(() =>
  import("../pages/relatorio/faturas/RelatorioFaturas")
);

const IndicePrecos = async(() =>
  import("../pages/cadastro/indiceReajuste/IndiceReajuste")
);

const DadosEstatisticosCadastro = async(() =>
  import("../pages/cadastro/dadosEstatisticos/DadosEstatisticos")
);

const RelatorioEspelhoPonto = async(() =>
  import("../pages/relatorio/espelhoponto/RelatorioEspelhoPonto")
);
const RelatorioTurno = async(() =>
  import("../pages/relatorio/turno/RelatorioTurno")
);
const RelatorioMutuarioTurno = async(() =>
  import("../pages/relatorio/turno/RelatorioMutuarioTurno")
);

const RelatorioTotalizadorJornada = async(() =>
  import("../pages/relatorio/totalizadorJornada/RelatorioTotalizadorJornada")
);
const RelatorioPontosDivergenteRelacaoTurno = async(() =>
  import("../pages/relatorio/pontosDivergenteRelacaoTurno/RelatorioPontosDivergenteRelacaoTurno")
);
const RelatorioBatidasForaLocalizacao = async(() =>
  import(
    "../pages/relatorio/batidasForaLocalizacao/RelatorioBatidasForaLocalizacao"
  )
);
const Mensagem = async(() => import("../pages/mensagem/Mensagem"));
const ConsultasGlobais = async(() => import("../pages/consultasglobais/ConsultasGlobais"));
const ResetPasswordInputSenha = async(() =>
  import("../pages/auth/ResetPasswordInputSenha")
);
const Empresas = async(() => import("../pages/empresa/Empresas"));
const CadastroRubricaPesquisa = async(() =>
  import("../pages/ponto/cadastroRubrica/CadastroRubricaPesquisa")
);
const EditarFechamento = async(() =>
  import("../pages/ponto/fechamento/EditarFechamento")
);
const MinhasSolicitacoes = async(() =>
  import("../pages/ponto/minhassolicitacoes/Solicitacoes")
);
const RelatorioLogUsuario = async(() =>
  import("../pages/relatorio/logusuario/RelatorioLogUsuario")
);
const RelatorioTentativaBatidas = async(() =>
  import("../pages/relatorio/tentativaBatidas/RelatorioTentativaBatidas")
);
const Parametro = async(() => import("../pages/cadastro/parametro/Parametro"));
const AlterarSenha = async(() =>
  import("../pages/cadastro/alterarsenha/AlterarSenha")
);
const Solicitacoes = async(() =>
  import("../pages/ponto/solicitacoes/Solicitacoes")
);
const GestorEmLote = async(() =>
  import("../pages/cadastro/gestorlote/GestorEmLote")
);
const FeriasAfastamentoLote = async(() =>
  import("../pages/cadastro/feriasafastamentolote/FeriasAfastamentoLote")
);
const NovoFechamento = async(() =>
  import("../pages/ponto/fechamento/NovoFechamento")
);
const Fechamento = async(() => import("../pages/ponto/fechamento/Fechamento"));
const RegistroPonto = async(() =>
  import("../pages/ponto/registroPonto/RegistroPonto")
);
const Documentos = async(() =>
  import("../pages/cadastro/documentos/Documentos")
);
const Notificacao = async(() =>
  import("../pages/cadastro/notificacao/Notificacao")
);
const Enquete = async(() => import("../pages/cadastro/enquete/Enquete"));
const Cargo = async(() => import("../pages/cadastro/cargo/Cargo"));
const CentroCusto = async(() =>
  import("../pages/cadastro/centrocusto/CentroCusto")
);
const ManterColaborador = async(() =>
  import("../pages/cadastro/colaborador/ManterColaborador")
);
const PesquisarColaborador = async(() =>
  import("../pages/cadastro/colaborador/PesquisarColaborador")
);
const Documento = async(() => import("../pages/cadastro/documento/Documento"));
const Empresa = async(() => import("../pages/cadastro/empresa/Empresa"));
const Feriado = async(() => import("../pages/cadastro/feriados/Feriado"));
const Lotacao = async(() => import("../pages/cadastro/lotacao/Lotacao"));
const Perfis = async(() => import("../pages/cadastro/perfis/Perfis"));
const RegimeContratacao = async(() =>
  import("../pages/cadastro/regimecontratacao/RegimeContratacao")
);
const BancoHoras = async(() => import("../pages/ponto/bancohoras/BancoHoras"));
const IntegracaoREP = async(() =>
  import("../pages/ponto/integracaorep/Integracao")
);
const LimiteGeografico = async(() =>
  import("../pages/ponto/limitegeografico/LimiteGeografico")
);
const MotivoAjuste = async(() =>
  import("../pages/ponto/motivoajuste/MotivoAjuste")
);
const ConsultaRegistroPontoPesquisarColaborador = async(() =>
  import(
    "../pages/ponto/consultaRegistroPonto/ConsultaRegistroPontoPesquisarColaborador"
  )
);
const ConsultaRegistroPonto = async(() =>
  import("../pages/ponto/consultaRegistroPonto/ConsultaRegistroPonto")
);
const ManterTurno = async(() => import("../pages/ponto/turno/ManterTurno"));
const PesquisarTurno = async(() =>
  import("../pages/ponto/turno/PesquisarTurno")
);
const RelatorioAbonoAjuste = async(() =>
  import("../pages/relatorio/abonoajuste/RelatorioAbonoAjuste")
);
const RelatorioAfastamentoFerias = async(() =>
  import("../pages/relatorio/afastamentoferias/RelatorioAfastamentoFerias")
);
const RelatorioTurnover = async(() =>
  import("../pages/relatorio/turnover/RelatorioTurnover")
);
const RelatorioAFD = async(() => import("../pages/relatorio/afd/RelatorioAFD"));
const RelatorioBancoHora = async(() =>
  import("../pages/relatorio/bancohoras/RelatorioBancoHora")
);
const RelatorioFerias = async(() =>
  import("../pages/relatorio/ferias/RelatorioFerias")
);
const RelatorioGestorColaborador = async(() =>
  import("../pages/relatorio/gestorcolaborador/RelatorioGestorColaborador")
);
const RelatorioUsuarios = async(() =>
  import("../pages/relatorio/usuarios/RelatorioUsuarios")
);
const AlteracaoColaboradorLote = async(() =>
  import(
    "../pages/cadastro/colaborador/alteracaoEmLote/AlteracaoColaboradorLote"
  )
);
const RelatorioAssinaturas = async(() =>
  import("../pages/relatorio/assinatura/RelatorioAssinaturas")
);

// Guards
const AuthGuard = async(() => import("../components/AuthGuard"));

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));
const SignInCadastro = async(() => import("../pages/auth/SignInCadastro"));

// Dashboards components
const Default = async(() => import("../pages/dashboards/Default"));
const Settings = async(() => import("../pages/manutencao/Settings"));
const Tasks = async(() => import("../pages/tarefas/Tasks"));
const Calendar = async(() => import("../pages/pages/Calendar"));

// Protected routes
const ProtectedPage = async(() => import("../pages/protected/ProtectedPage"));

const suporteRoutes = {
  path: "/",
  header: "Suporte",
  permission: [],
  perfil: ["SUPORTE", "FINANCEIRO", "VENDEDOR"],
  children: [],
  component: null,
  guard: AuthGuard,
};

const empresasRoutes = {
  id: "Empresas",
  path: "/empresas",
  perfil: ["SUPORTE", "FINANCEIRO", "VENDEDOR"],
  icon: <AiOutlineBank />,
  permission: [],
  component: Empresas,
  children: null,
  guard: AuthGuard,
};

const mensagensRoutes = {
  id: "Mensagens",
  path: "/mensagens",
  perfil: ["SUPORTE", "FINANCEIRO"],
  icon: <BiMessageAltCheck />,
  permission: [],
  component: Mensagem,
  children: null,
  guard: AuthGuard,
};


const consultasGlobaisRoutes = {
  id: "Consultas Globais",
  path: "/consultasglobais",
  perfil: ["SUPORTE", "FINANCEIRO", "VENDEDOR"],
  icon: <Search />,
  permission: [],
  component: ConsultasGlobais,
  children: null,
  guard: AuthGuard,
};

const faturasRoutes = {
  id: "Faturas",
  path: "/faturas",
  perfil: ["FINANCEIRO", "VENDEDOR"],
  icon: <FaFileInvoiceDollar />,
  permission: [],
  component: RelatorioFaturas,
  children: null,
  guard: AuthGuard,
};

const indiceReajusteRoutes = {
  id: "Índice de Reajuste",
  path: "/indiceReajuste",
  perfil: ["FINANCEIRO"],
  icon: <GiPayMoney />,
  permission: [],
  component: IndicePrecos,
  children: null,
  guard: AuthGuard,
};

const dadosEstatisticosRoutes = {
  id: "Dados Estatísticos",
  path: "/dadosEstatisticos",
  perfil: ["FINANCEIRO"],
  icon: <BsGraphUpArrow />,
  permission: [],
  component: DadosEstatisticosCadastro,
  children: null,
  guard: AuthGuard,
};

const dashboardsRoutes = {
  id: "Dashboard",
  path: "/dashboard",
  permission: ["visualiza_dashboard_ponto"],
  header: "Operacional",
  icon: <RiDashboardLine />,
  containsHome: true,
  component: Default,
  guard: AuthGuard,
};

const consultasRoutes = {
  id: "Cadastros",
  path: "/cadastros",
  permission: [
    "manutencao_dados_servidor",
    "manter_centro_custo",
    "manter_regime_contratacao",
    "manter_lotacao",
    "manter_cargo",
    "manter_documentos_colaborador",
    "gerenciamento_eventos_calendario",
    "manutencao_perfis",
    "manter_parametros",
    "manter_evento_folha",
  ],
  icon: <PlusSquare />,
  guard: AuthGuard,
  children: [
    {
      path: "/cadastro/colaborador",
      name: "Colaborador",
      permission: ["manutencao_dados_servidor"],
      component: PesquisarColaborador,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/centroCusto",
      name: "Centro de Custo",
      permission: ["manter_centro_custo"],
      component: CentroCusto,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/regimeContratacao",
      name: "Regime de Contratação",
      permission: ["manter_regime_contratacao"],
      component: RegimeContratacao,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/lotacao",
      name: "Lotação",
      permission: ["manter_lotacao"],
      component: Lotacao,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/cargo",
      name: "Cargo",
      permission: ["manter_cargo"],
      component: Cargo,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/documentos",
      name: "Documentos do Colaborador",
      permission: ["manter_documentos_colaborador"],
      component: Documento,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/eventos",
      name: "Feriados",
      permission: ["gerenciamento_eventos_calendario"],
      component: Feriado,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/perfis",
      name: "Perfis",
      permission: ["manutencao_perfis"],
      component: Perfis,
      guard: AuthGuard,
    },
    {
      path: "/cadastro/colaborador/manter",
      hiddenSidebar: true,
      permission: ["manutencao_dados_servidor"],
      component: ManterColaborador,
      guard: AuthGuard,
    },
    {
      path: "/cadastro/colaborador/lote",
      name: "Alterações Em Lote",
      permission: ["manutencao_dados_servidor"],
      component: AlteracaoColaboradorLote,
      guard: AuthGuard,
    },
    {
      path: "/cadastro/colaborador/manter/:idMutuarioParam",
      hiddenSidebar: true,
      permission: ["manutencao_dados_servidor"],
      component: ManterColaborador,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/ferias/afastamento/lote",
      name: "Férias/Afastamento em Lote",
      permission: ["manutencao_dados_servidor"],
      component: FeriasAfastamentoLote,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/gestor/lote",
      name: "Gestor em Lote",
      permission: ["manutencao_dados_servidor"],
      component: GestorEmLote,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/parametros",
      name: "Configurações",
      permission: ["manter_parametros"],
      component: Parametro,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/rubrica",
      name: "Rubrica",
      permission: ["manter_evento_folha"],
      component: CadastroRubricaPesquisa,
    },
  ],
  component: null,
};

const pontoRoutes = {
  id: "Ponto",
  path: "/ponto",
  permission: [
    "realizar_fechamento_ponto",
    "banco_horas_ponto",
    "escala_folga",
    "gerencia_localidade_ponto",
    "motivo_ajuste_ponto",
    "integracao_rep",
    "manter_horarios_ponto",
    "manutencao_dados_servidor",
    "fechamento_ponto",
    "aprova_solicitacoes_ponto",
  ],
  icon: <Clock />,
  children: [
    {
      path: "/ponto/consultaRegistroPonto",
      name: "Consulta de Reg. do Ponto",
      permission: [],
      component: ConsultaRegistroPontoPesquisarColaborador,
      guard: AuthGuard,
    },
    {
      path: "/ponto/consultaRegistroPonto/manter",
      name: "Consulta de Reg. do Ponto",
      permission: [],
      hiddenSidebar: true,
      component: ConsultaRegistroPonto,
      guard: AuthGuard,
    },
    {
      path: "/ponto/consultaRegistroPonto/manter/:idMutuarioParam",
      name: "Consulta de Reg. do Ponto",
      permission: [],
      hiddenSidebar: true,
      component: ConsultaRegistroPonto,
      guard: AuthGuard,
    },
    {
      path: "/ponto/bancoHoras",
      name: "Banco de Horas",
      permission: ["banco_horas_ponto"],
      component: BancoHoras,
      guard: AuthGuard,
    },
    {
      path: "/ponto/limiteGeografico",
      name: "Limites Geográficos",
      permission: ["gerencia_localidade_ponto"],
      component: LimiteGeografico,
      guard: AuthGuard,
    },
    {
      path: "/ponto/motivoAusencia",
      name: "Motivos de Ausência",
      permission: ["motivo_ajuste_ponto"],
      component: MotivoAjuste,
      guard: AuthGuard,
    },
    {
      path: "/ponto/integracaoREP",
      name: "Integração REP",
      permission: ["integracao_rep"],
      component: IntegracaoREP,
      guard: AuthGuard,
    },
    {
      path: "/ponto/turno",
      name: "Turno",
      permission: ["manter_horarios_ponto"],
      component: PesquisarTurno,
      guard: AuthGuard,
    },
    {
      path: "/ponto/escala/folga/sobreaviso",
      name: "Escala de Folgas / Sobreaviso",
      permission: ["escala_folga", "sobreaviso"],
      component: EscalaFolga,
      guard: AuthGuard,
    },
    {
      path: "/ponto/turno/manter",
      hiddenSidebar: true,
      permission: ["manter_horarios_ponto"],
      component: ManterTurno,
      guard: AuthGuard,
    },
    {
      path: "/ponto/turno/manter/:idTurnoParam",
      hiddenSidebar: true,
      permission: ["manter_horarios_ponto"],
      component: ManterTurno,
      guard: AuthGuard,
    },
    {
      path: "/ponto/fechamento",
      name: "Fechamento",
      permission: ["fechamento_ponto", "realizar_fechamento_ponto"],
      component: Fechamento,
      guard: AuthGuard,
    },
    {
      path: "/ponto/fechamento/novo",
      permission: ["fechamento_ponto"],
      hiddenSidebar: true,
      component: NovoFechamento,
      guard: AuthGuard,
    },
    {
      path: "/ponto/fechamento/:idFechamentoPonto",
      permission: ["fechamento_ponto"],
      hiddenSidebar: true,
      component: EditarFechamento,
      guard: AuthGuard,
    },
    {
      path: "/ponto/solicitacoes",
      name: "Solicitações",
      permission: ["aprova_solicitacoes_ponto"],
      component: Solicitacoes,
      guard: AuthGuard,
    },
    {
      path: "/planos",
      hiddenSidebar: true,
      component: Pricing,
      guard: AuthGuard,
    },
    {
      path: "/ponto/escala/:idColaborador",
      hiddenSidebar: true,
      component: ProgramarEscala,
      guard: AuthGuard,
    },
  ],
  component: null,
};

const pagesRoutes = {
  id: "Relatórios",
  path: "/relatorios",
  permission: [
    "sobreaviso",
    "rel_adicional_noturno",
    "escala_folga",
    "rel_afd",
    "relatorio_assinatura_documentos",
    "rel_ferias",
    "rel_batidas_fora_localidade",
    "rel_espelho_ponto",
    "rel_mutuario_turno",
    "relatorio_turnos",
    "rel_afastamento_ferias",
    "rel_absenteismo",
    "rel_ajustes_ponto",
    "rel_banco_horas",
    "rel_gestores",
    "rel_log_usuario",
    "relatorio_usuarios",
    "rel_turnover",
    "rel_totalizador_jornada",
    "visualiza_dashboard_ponto",
    "relatorio_dados_cadastrais",
    "rel_selfie_batida_ponto",
  ],
  icon: <HiOutlineDocumentReport />,
  children: [
    {
      path: "/relatorios/absenteismo",
      name: "Absenteísmo",
      permission: ["rel_absenteismo"],
      component: RelatorioAbsenteismo,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/ponto/adicionalNoturna",
      name: "Adicional Noturno",
      permission: ["rel_adicional_noturno"],
      component: RelatorioAdicionalNoturno,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/afastamento/ferias",
      name: "Afastamento/Férias",
      permission: ["rel_afastamento_ferias"],
      component: RelatorioAfastamentoFerias,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/afd",
      name: "AFD/AFDT/ACJEF/AEJ",
      permission: ["rel_afd"],
      component: RelatorioAFD,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/ajuste/abono",
      name: "Ajuste/Abono de Ponto",
      permission: ["rel_ajustes_ponto"],
      component: RelatorioAbonoAjuste,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/assinatura/documentos",
      name: "Assinatura de Documentos",
      permission: ["relatorio_assinatura_documentos"],
      component: RelatorioAssinaturas,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/bancoHora",
      name: "Banco de Horas",
      permission: ["rel_banco_horas"],
      component: RelatorioBancoHora,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/batidasDivergentes",
      name: "Batidas Divergentes ao Turno ",
      permission: ["batidas_divergentes_turno"],
      component: RelatorioPontosDivergenteRelacaoTurno,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/ferias",
      name: "Controle de Férias",
      permission: ["rel_ferias"],
      component: RelatorioFerias,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/dadoscadastrais",
      name: "Dados Cadastrais",
      permission: ["relatorio_dados_cadastrais"],
      component: RelatorioDadosCadastrais,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/escalafolga",
      name: "Escala de Folgas",
      permission: ["escala_folga"],
      component: RelatorioEscalaFolga,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/espelho/jornada",
      name: "Espelho de Jornada",
      permission: ["rel_espelho_ponto"],
      component: RelatorioEspelhoPonto,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/faltas",
      name: "Faltas/Atrasos",
      permission: ["visualiza_dashboard_ponto"],
      component: RelatorioFaltas,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/gestoresColaboradores",
      name: "Gestores do Colaborador",
      permission: ["rel_gestores"],
      component: RelatorioGestorColaborador,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/insights",
      name: "Insights",
      permission: ["rel_insights"],
      component: RelatorioInsights,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/intraInterJornada",
      name: "Intra e Inter Jornada",
      permission: ["rel_relatorio_inter_intra_jornada"],
      component: RelatorioInterIntraJornada,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/batidasLocalizacao",
      name: "Localização das Batidas de Ponto",
      permission: ["rel_batidas_fora_localidade"],
      component: RelatorioBatidasForaLocalizacao,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/auditoria",
      name: "Log do Usuário",
      permission: ["rel_log_usuario"],
      component: RelatorioLogUsuario,
      guard: AuthGuard,
    },    
    {
      path: "/relatorios/periodoExperiencia",
      name: "Período de Experiência",
      permission: ["rel_periodo_experiencia"],
      component: RelatorioPeriodoExperiencia,
      guard: AuthGuard,
    },
    
    {
      path: "/relatorios/selfieRegistroPonto",
      name: "Selfie Registro Ponto",
      permission: ["rel_selfie_batida_ponto"],
      component: RelatorioAuditoriaFoto,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/sobreaviso",
      name: "Sobreaviso",
      permission: ["sobreaviso"],
      component: RelatorioSobreaviso,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/tentativa/batidas",
      name: "Tentativas de Batida de Ponto",
      permission: ["rel_tentativa_batidas"],
      component: RelatorioTentativaBatidas,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/totalizadorJornada",
      name: "Totalizador de Jornada",
      permission: ["rel_totalizador_jornada"],
      component: RelatorioTotalizadorJornada,
      guard: AuthGuard,
    },
    
    {
      path: "/relatorios/turno",
      name: "Turnos",
      permission: ["relatorio_turnos"],
      component: RelatorioTurno,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/turnoColaborador",
      name: "Turnos do Colaborador",
      permission: ["rel_mutuario_turno"],
      component: RelatorioMutuarioTurno,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/turnover",
      name: "Turnover",
      permission: ["rel_turnover"],
      component: RelatorioTurnover,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/usuarios",
      name: "Usuários",
      permission: ["relatorio_usuarios"],
      component: RelatorioUsuarios,
      guard: AuthGuard,
    },
  ],
  component: null,
};

const empresaRoute = {
  id: "Empresa",
  path: "/empresa",
  permission: [],
  icon: <ShoppingCart />,
  component: Empresa,
  children: null,
  guard: AuthGuard,
};

const authRoutes = {
  id: "Auth",
  path: "/",
  permission: [],
  icon: <Users />,
  children: [
    {
      path: "/",
      name: "Login",
      component: SignIn,
    },
    {
      path: "/auth/posCadastro/:token",
      hiddenSidebar: true,
      component: SignInCadastro,
    },
    {
      path: "/auth/reset-password",
      name: "Esqueci Minha Senha",
      component: ResetPassword,
    },
    {
      path: "/auth/mfa",
      name: "Token MFA",
      component: TokenMFA,
    },
    {
      path: "/recuperarSenha/:hash",
      name: "Alterar Senha",
      component: ResetPasswordInputSenha,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

const componentsRoutes = {
  path: "/",
  header: "Produtividade",
  permission: [],
  children: [],
  component: null,
  guard: AuthGuard,
};

const tarefasRoutes = {
  id: "Tarefas",
  path: "/tarefas",
  icon: <FaTasks />,
  permission: [],
  component: Tasks,
  children: null,
  guard: AuthGuard,
};

const calendarioRoutes = {
  id: "Calendário",
  path: "/calendario",
  permission: [],
  icon: <CalendarIcon />,
  component: Calendar,
  children: null,
  guard: AuthGuard,
};

const notificacaoRoutes = {
  id: "Notificações",
  path: "/notificacao/manter",
  permission: [],
  icon: <TiMessages />,
  component: Notificacao,
  children: null,
  guard: AuthGuard,
};

const enqueteRoutes = {
  id: "Enquetes",
  path: "/enquete/manter",
  permission: [],
  icon: <RiSurveyLine />,
  component: Enquete,
  children: null,
  guard: AuthGuard,
};

const meuPontoRoutes = {
  path: "/",
  header: "Minha Área",
  permission: [],
  children: [],
  component: null,
  guard: AuthGuard,
};

const ponto = {
  id: "Registro de Ponto",
  permission: [],
  path: "/ponto",
  icon: <Clock />,
  component: RegistroPonto,
  children: null,
  guard: AuthGuard,
};

const documentos = {
  id: "Meus Documentos",
  permission: [],
  path: "/meusDocumentos",
  icon: <FileText />,
  component: Documentos,
  children: null,
  guard: AuthGuard,
};

const alterarSenha = {
  id: "Alterar Senha",
  permission: [],
  path: "/alterarSenha",
  icon: <RiLockPasswordLine />,
  component: AlterarSenha,
  children: null,
  guard: AuthGuard,
};

const meuPerfil = {
  id: "Meus Dados",
  permission: [],
  path: "/manutencao/profile",
  icon: <CgProfile />,
  component: Settings,
  children: null,
  guard: AuthGuard,
};

const minhasSolicitacoes = {
  id: "Minhas Solicitações",
  permission: [],
  path: "/ponto/minhasSolicitacoes",
  icon: <MdBorderColor />,
  component: MinhasSolicitacoes,
  children: null,
  guard: AuthGuard,
};

// This route is only visible while signed in
const protectedPageRoutes = {
  id: "Private",
  permission: [],
  path: "/app",
  component: ProtectedPage,
  children: null,
  guard: AuthGuard,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  dashboardsRoutes,
  consultasRoutes,
  pontoRoutes,
  pagesRoutes,
  suporteRoutes,
  empresasRoutes,
  mensagensRoutes,
  consultasGlobaisRoutes,
  faturasRoutes,
  indiceReajusteRoutes,
  dadosEstatisticosRoutes,
  empresaRoute,
  componentsRoutes,
  notificacaoRoutes,
  enqueteRoutes,
  calendarioRoutes,
  tarefasRoutes,
  meuPontoRoutes,
  ponto,
  minhasSolicitacoes,
  documentos,
  meuPerfil,
  alterarSenha,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes that are protected
export const protectedRoutes = [protectedPageRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  dashboardsRoutes,
  consultasRoutes,
  pontoRoutes,
  pagesRoutes,
  suporteRoutes,
  empresasRoutes,
  mensagensRoutes,
  consultasGlobaisRoutes,
  faturasRoutes,
  indiceReajusteRoutes,
  dadosEstatisticosRoutes,
  componentsRoutes,
  notificacaoRoutes,
  enqueteRoutes,
  calendarioRoutes,
  tarefasRoutes,
  meuPontoRoutes,
  ponto,
  minhasSolicitacoes,
  documentos,
  meuPerfil,
  alterarSenha,
];
