import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import React from "react";
import { X } from "react-feather";

const CancelarButton = ({
  onClick,
  title = "Cancelar",
  style,
  color = "primary",
  variant = "outlined",
  carregando = false
}) => {
  return (
    <>
      <LoadingButton
        variant={variant}
        color={color}
        loading={carregando}
        startIcon={<X />}
        style={style}
        onClick={() => onClick()}
      >
        {title}
      </LoadingButton>
    </>
  );
};

export default CancelarButton;
