import styled from "@emotion/styled";
import { IconButton as MuiIconButton, Tooltip } from "@mui/material";
import React from "react";
import { HelpCircle } from "react-feather";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function Support() {

  const handleSignOut = async () => {
    window.open("https://ajuda.pontoicarus.com.br/", '_blank');
   
  };

  return (
    <React.Fragment>
      
        <Tooltip title="Base de Conhecimento">
          <IconButton
            aria-owns={Boolean(null) ? "menu-appbar" : undefined}
            aria-haspopup="true"
            onClick={handleSignOut}
            color="inherit"
            size="large">
          
            <HelpCircle />

          </IconButton>
        </Tooltip>
      
    </React.Fragment>
  );
}

export default Support;
