import { Avatar, Chip } from "@mui/material";
import React from "react";
import MutuarioService from "../../services/MutuarioService";
import AutoCompleteAsyncPersist from "./autocomplete/AutoCompleteAsyncPersist";
import PropTypes from "prop-types";

const ColaboradorAutoComplete = (props) => {
  const handleFetchAPI = async (valueSearch) => {
    const response = await MutuarioService.pesquisarMutuario(
      valueSearch,
      null,
      null,
      null,
      null,
      null,
      0
    );
    return response.content;
  };

  const renderTags = (tagValue, getTagProps) => {
    return tagValue.map((option, index) => (
      <Chip
        color="secondary"
        avatar={
          <Avatar alt={option.pessoa.nome} src={option.pessoa.caminhoFoto} />
        }
        label={option.pessoa.nome}
        {...getTagProps({ index })}
      />
    ));
  };

  return (
    <AutoCompleteAsyncPersist
      name="autoColaborador"
      label={props.label}
      itemLabel={"pessoa.nome"}
      renderTags={renderTags}
      fullWidth
      onFetchAPI={handleFetchAPI}
      {...props}
    />
  );
};

ColaboradorAutoComplete.propTypes = {
  label: PropTypes.string,
};

ColaboradorAutoComplete.defaultProps = {
  label: "Colaborador",
};

export default ColaboradorAutoComplete;
