import {
  AppBar,
  Box,
  Button,
  Dialog as DialogMaterial,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Hidden,
  IconButton as MuiIconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { Alert as AlertLab } from '@mui/material';
import { darken } from "polished";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { Check, X } from "react-feather";
import { AiOutlineFileSearch } from "react-icons/ai";
import { FaUserEdit } from "react-icons/fa";
import { MdPayment } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled, { withTheme } from "styled-components/macro";
import * as types from "../constants";
import UsuarioService from "../services/UsuarioService";
import DateUtil from "../utils/DateUtil";
import PermissionUtil from "../utils/PermissionUtil";
import Alert from "./alert/Alert";
import Dialog from "./Dialog";
import ColaboradorAutoComplete from "./icarus/ColaboradorAutoComplete";
import EmpresaComplete from "./icarus/EmpresaComplete";
import MessagesDropdown from "./MessagesDropdown";
import NotificationsDropdown from "./NotificationsDropdown";
import RegistraPontoDropdown from "./RegistraPontoDropdown";
import UserDropdown from "./UserDropdown";
import Support from "./Support";



const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Search = styled.div`
  border-radius: 2px;
  
  display: block;
  position: relative;
  width: 100%;
  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }
`;


const SearchColaborador = styled.div`
 border-radius: 2px;

  display: none;
  position: relative;
  width: 100%;
  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchButton = styled.div`
  border-radius: 2px;
 
  display: none;
  position: relative;
  width: 100%;
  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const Input = styled(ColaboradorAutoComplete)`
  color: inherit;
  padding: 8px;
  width: 100%;
  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 160px;
  }
`;

const InputEmpresa = styled(EmpresaComplete)`
  color: inherit;
  padding: 8px;
  width: 100%;
  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 160px;
  }
`;

const AppBarComponent = ({ onDrawerToggle, theme }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer);
  const [showDialog, setShowDialog] = useState(
    user.firstAccess && user.profile.papel === "ADMINISTRADORA"
  );
  const [dataEntrada] = useState(new Date());
  const [showConfirmClose, setShowConfirmClose] = useState(false);
  const [showDialogSelecao, setShowDialogSelecao] = useState();
  const [selecionado, setSelecionado] = useState();

  const selecionar = (value) => {
    setSelecionado(value);

    if (PermissionUtil.hasPermission("manutencao_dados_servidor", user)) {
      setShowDialogSelecao(true);
    } else {
      irVisualizacaoPonto(value.idMutuario);
    }
  };

  const irVisualizacaoPonto = (id) => {
    history.push(`/ponto/consultaRegistroPonto/manter/${id}`);
    history.go(0);
  };

  const irEdicaoColaborador = (id) => {
    history.push(`/cadastro/colaborador/manter/${id}`);
    history.go(0);
  };

  const changeEmpresa = (value) => {
    UsuarioService.changeCompany(value.idEmpresa).then((response) => {
      dispatch({ type: types.CHANGE_EMPRESA, user: response });
      Alert.success("Sucesso", "Empresa alterada com sucesso");
      history.go(0);
    });
  };

  const changeFirstAccess = () => {
    dispatch({ type: types.CHANGE_LAST_ACCESS, firstAccess: false });
  };

  const closeDialogConfirm = (valida) => {
    let diferenca = DateUtil.diferencaEmSegundos(dataEntrada, new Date());

    if (valida && diferenca > 100) {
      setShowDialog(false);
      changeFirstAccess();
    } else {
      setShowDialog(false);
      setShowConfirmClose(true);
    }
  };

  return (
    <React.Fragment>
      {showConfirmClose && (
        <DialogMaterial
          open={showConfirmClose}
          onClose={() => setShowConfirmClose(false)}
          aria-labelledby="confirm-dialog"
        >
          <DialogTitle id="confirm-dialog">
            Deseja Realmente Fechar?
          </DialogTitle>
          <DialogContent>
            <AlertLab severity="warning" variant="outlined">
              Este vídeo é muito importante, deseja mesmo fechar sem
              visualiza-lo por completo?
            </AlertLab>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                setShowDialog(true);
                changeFirstAccess();
              }}
              startIcon={<X />}>
              Não
            </Button>
            <Button
              startIcon={<Check />}
              color="secondary"
              variant="contained"
              onClick={() => {
                setShowConfirmClose(false);
                changeFirstAccess();
              }}
            >
              Sim
            </Button>
          </DialogActions>
        </DialogMaterial>
      )}

      {showDialogSelecao && (
        <Dialog
          show={showDialogSelecao}
          title="O Que Deseja Fazer?"
          maxWidth={"xs"}
          onRequestClose={() => {
            setShowDialogSelecao(false);
          }}
        >
          <Grid container style={{ textAlign: "center" }}>
            <Grid item md={6} sm={6} xs={6}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<FaUserEdit />}
                onClick={() => irEdicaoColaborador(selecionado.idMutuario)}
              >
                Editar Colaborador
              </Button>
            </Grid>

            <Grid item md={6} sm={6} xs={6}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AiOutlineFileSearch />}
                onClick={() => irVisualizacaoPonto(selecionado.idMutuario)}
              >
                Visualizar Pontos
              </Button>
            </Grid>
          </Grid>
        </Dialog>
      )}

      {showDialog && (
        <Dialog
          show={showDialog}
          title="Obrigado por confiar em nós, iremos retribuir a confiança!"
          onRequestClose={() => {
            setShowDialog(true);
            closeDialogConfirm(true);
          }}
        >
          <Typography variant="h4">
            Olá, {user.person.primeiroNome}, que bom ter você aqui.
          </Typography>
          <Box my={3} />
          <Typography style={{ fontSize: 14 }}>
            Estaremos acompanhando toda sua jornada, inclusive você já conta com
            um especialista em Ponto Eletrônico que vai te ligar e ajudar nas
            configurações do sistema, ok?
          </Typography>
          <Box my={3} />
          <Typography style={{ fontSize: 14 }}>
            <b>O especialista ira te ligar de um DDD 48</b> para te ajudar nos
            primeiros passos. Fique atento!
          </Typography>
          <div
            style={{
              position: "relative",
              paddingBottom: "56.22254758418741%",
              height: 0,
              marginTop: 15,
            }}
          >
            <iframe
              width={isMobile ? 260 : 550}
              height="315"
              src={`https://www.youtube.com/embed/FnKR1xDZla0?autoplay=1`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </Dialog>
      )}

      <AppBar position="sticky" elevation={0} style={{ background: theme.header.background, color: theme.header.color}}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                  size="large">
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xl={9} md={9}>
              <Grid container>
                {PermissionUtil.hasPermission(
                  "manutencao_dados_servidor",
                  user
                ) &&
                  user.profile.papel !== "COLABORADOR" && (
                    <Grid item md={4}>
                      <SearchColaborador>
                        <Input onChange={(value) => selecionar(value)} />
                      </SearchColaborador>
                    </Grid>
                  )}
                {(user.profile.papel === "SUPORTE" ||
                  user.profile.papel === "FINANCEIRO" ||
                  (user.profile.papel === "ADMINISTRADORA" &&
                    user.companyLinked &&
                    user.companyLinked.length > 0)) && (
                    <Grid item md={4}>
                      <Search>
                        <InputEmpresa
                          value={user.company}
                          onChange={(value) => {
                            changeEmpresa(value);
                          }}
                        />
                      </Search>
                    </Grid>
                  )}

                {user.company.diasFaltantesTrial !== undefined &&
                  user.company.diasFaltantesTrial !== null &&
                  (user.profile.papel === "ADMINISTRADORA" ||
                    user.profile.papel === "SUPORTE" ||
                    user.profile.papel === "FINANCEIRO") && (
                    <Grid item md={4} style={{ alignSelf: "center" }}>
                      <SearchButton>
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{ height: 53 }}
                          startIcon={<MdPayment />}
                          onClick={() => history.push("/planos")}
                        >
                          <div
                            style={{
                              flexDirection: "row",
                              fontSize: 12,
                              fontWeight: "600",
                            }}
                          >
                            {user.company.diasFaltantesTrial < 0 ? (
                              <text style={{ color: "red" }}>
                                Seu período de avaliação terminou
                              </text>
                            ) : (
                              <text>
                                Seu período de avaliação termina em{" "}
                                {user.company.diasFaltantesTrial} dias
                              </text>
                            )}
                          </div>
                        </Button>
                      </SearchButton>
                    </Grid>
                  )}
              </Grid>
            </Grid>

            <Grid item xs />
            <Grid item>
              <RegistraPontoDropdown />
              <Support />
              <MessagesDropdown />
              <NotificationsDropdown />
              <UserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(AppBarComponent);
